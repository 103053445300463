
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





















































































































header {
	margin-bottom: $default_padding/2;
}

ol {
	list-style-type: none;
	padding-left: 0;

	ol {
		padding-left: $default_padding
	}
}

.input-group {
	display: flex;
	flex-flow: column nowrap;

	& > p {
		text-align: center;
		font-weight: $font__semibold;
	}

	.input-group-rows {
		margin-bottom: 0;
	}

	.input-group-row {
		&.item,
		.target-name {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: $default_padding * 0.5;
			margin-bottom: $default_padding;
		}

		&.target {
			border: 2px dashed $border_color;
			border-radius: $border_radius;
			padding: $default_padding * 0.5;
			margin-bottom: $default_padding;

			.items p {
				margin-bottom: $default_padding * 0.5;
			}
		}

		&.item {
			margin-bottom: $default_padding * 0.5;
		}

		input,
		.bool-input {
			margin: 0;
		}

		.button {
			button {
				padding: 5px;
			}
		}
	}

	& > .button {
		align-self: center;
	}

	button {
		background-color: $color__dark;

		&:hover {
			background-color: rgba($color: $color__dark, $alpha: 0.8);
		}
	}
}
